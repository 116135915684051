import * as React from 'react';
import { styled } from '@mui/system';

import Box from '@mui/system/Box';

import Layout from '../../components/Layout';
import { Container, Typography } from '@mui/material';
import TeamList from '../../components/UpdateList';

const UpdatesHeader = styled(Box)({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundImage: `url('/img/blog-index.jpg')`,
	minHeight: '20vh',
	pt: 4,
	pb: 4,
});

const MainContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	// width: '100%',
	zIndex: 1,
});

export default class UpdatesIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<UpdatesHeader className='full-width-image-container margin-top-0'>
					<Typography variant='h1'>Latest Stories</Typography>
				</UpdatesHeader>
				<Container>
					<MainContent className='section' sx={{ pt: 4 }}>
						<TeamList />
					</MainContent>
				</Container>
			</Layout>
		);
	}
}
